.main-text {
    font-family: Trueno;
    font-size: 3rem;
}

.subtext {
    font-family: 'Courier New', Courier, monospace;
    font-size: 1.5rem;
}

.text {
    margin-bottom: 20vh;
}

.container {
    height: 100vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: rgb(23, 23, 23);
    color: rgb(233, 237, 241);
}

.parent-container {
    height: 100vh;
    width: 100vh;
}